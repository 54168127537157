<template>
<div 
v-loading.fullscreen="loading"
element-loading-spinner="el-icon-loading"
:element-loading-text=loadingText
>
  <form id="payment-form">
    <div id="card-element" class="border-1 p-2 rounded mx-6">
      <!-- Elements will create input elements here -->
    </div>

    <!-- We'll put the error messages in this element -->
    <div class="mt-5 mx-auto text-red-400 flex justify-center" v-if="paymentConfirmationError.includes('validation_error')">
		<p>{{message}}</p>
    </div>
	<div class="mt-5 mx-auto text-red-400 flex justify-center" v-else-if="paymentConfirmationError.includes('timeout')">
 		<p>Your payment has been confirmed but there is an issue processing your registration.<br/>No need to resubmit. We will contact you.</p>
    </div>
    <div class="mt-5 mx-auto text-red-400 flex justify-center" v-else-if="message">
 		<p>The credit card processor experienced an error. Please try again.<br/>If problem persists, please contact your bank or us at info@muenchworkshops.com</p>
    </div>
    <div class="flex justify-center">
      <button v-if="formFields.type == 'registration'" id="submit" class=" bg-muench text-white p-3 mt-4 rounded text-sm">Complete Registration and Payment</button>
      <button v-else id="submit" class=" bg-muench text-white p-3 mt-4 rounded text-sm">Complete Payment</button>
    </div>
  </form>
</div>
</template>

<script>
import sharedAPI from "@/api/SharedAPI.js";

export default {
props: ["formFields", "title", "paymentIntent"],
data () {
  this.pk = "pk_live_bR2dqISTvS3FKsFvmhgc5R0C";
//    this.pk = "pk_test_51J7KxzHM1ClCLQWIfEXO8k32CrSJ74umlJx2MBOGkh4yVTMqSuq1s1PmHvhtRwMCAUT4UWx76pdm569bRDthC9i900ZPjuvqB2";
  return {
    loading: false,
    loadingText: "Verifying...",
    token: null,
    card: '',
    message: '',
    paymentConfirmationError: '',
    cardDeclined: null,
    createError: null,
    triggerEmit: false

  };
},
watch:{
  // card :function ( val){
  //   var displayError = document.getElementById('card-errors');
  //   if (event.error) {
  //     displayError.textContent = event.error.message;
  //   } else {
  //     displayError.textContent = '';
  //   }
  // }
  // card() {
  //     this.message = ''
  //     this.code = ''
  //     this.status = ''
  //     this.param = ''
  //     this.type = ''
  // }
},
mounted(){
  this.setUpStripe()
},
methods: {
  async setUpStripe() {
    try{
      //create payment intent on server
      var DOM = this
	  DOM.loadingText = "Processing payment and creating registration. Please wait..."
      DOM.loading = true

      if (window.Stripe === undefined || this.message !='') {
        throw 'Stripe V3 library not loaded!'
      }
      const stripe = window.Stripe('pk_live_bR2dqISTvS3FKsFvmhgc5R0C');
    //   const stripe = window.Stripe('pk_test_51J7KxzHM1ClCLQWIfEXO8k32CrSJ74umlJx2MBOGkh4yVTMqSuq1s1PmHvhtRwMCAUT4UWx76pdm569bRDthC9i900ZPjuvqB2');

      const elements = stripe.elements();
      var style = {
        base: {
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#606266"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      DOM.card = elements.create("card", { style: style });
      DOM.card.mount("#card-element");
      var form = document.getElementById('payment-form');
      DOM.loading = false
      form.addEventListener('submit', function(ev) {
        ev.preventDefault();
        DOM.loading = true
        stripe.confirmCardPayment(DOM.paymentIntent.clientSecret, {
          payment_method: {
            card: DOM.card,
          },
        }).then(function(result) {
          // return
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            DOM.loading = false            
            if(result.error.type.includes("validation_error")) {
              DOM.paymentConfirmationError = result.error.type
			  DOM.message = result.error.message
            } else{
				DOM.paymentConfirmationError = result.error.type
            	DOM.message = result.error.message
            }
			let params = {
				error: result.error.message,
				workshopDetails: DOM.formFields,
        		fullStripeResponse: result
			}
			sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
          } else {
            // console.log(result)
            DOM.paymentConfirmationError = ''
            if (result.paymentIntent.status === 'succeeded') {
              DOM.formFields.stripeResult = result
              var pageData = DOM.formFields;

              pageData = JSON.stringify(pageData);
              if (DOM.formFields.type == "pay") {
                var url = "submitPayment.php?";
              }else{
                var url = "submitRegistration.php?";
              }
              axios
              .post(url, pageData,{timeout: 120000})
              .then(function(response) {
                var data = response.data

                //go to confirmation page
                var sr = JSON.parse(data.response.scriptResult)
                // if (sr.code != undefined){
                //received response from FileMaker
                var params = {
                  "result": sr,
                  "fields": DOM.formFields
                }
                // return
                DOM.$router.push({name: 'confirmation', params: {'data': params}})
                // }

                DOM.loading = false;
              })
              .catch(function(error) {
				console.log(error)
				if(JSON.stringify(error).includes('timeout')){
					DOM.paymentConfirmationError = 'timeout'
					DOM.message = error
				}
				else{
					DOM.$message({
					  message: error, 
					  type: 'error',
					  duration: 6000
					})
				}
                let params = {
                  error: error,
                  workshopDetails: DOM.formFields,
                  fullStripeResponse: result
                }
				DOM.loading = false;
                sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
              });
            }
          }
        });
      });
    }
    catch(e){
        var message = await this.displayUserError(e)
        DOM.$message({
          message: message, 
          type: 'error',
          duration: 6000
        })
        let params = {
          error: e,
          workshopDetails: DOM.formFields,
          fullStripeResponse: result
        }
        sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
    }
    finally{
      DOM.loading = false;
    }
  },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
